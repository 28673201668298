
import Vue from 'vue';

import { mapState, mapActions } from 'vuex';

import { dreamsActions } from '@/store/modules/dreams/names';
import { ComposedState } from '@/types';

export default Vue.extend({
    name: 'CreateDream',

    data() {
        return {
            selectList: ['Пользовательская', 'Благотворительная'],
            dialog: false,
            dreamItem: {
                title: null,
                user_id: null,
                description: null,
                category_id: null,
                goal: null,
                type_dream: null,
                picture: null,
                currency_id: null as null | number,
            },
        };
    },

    computed: {
        ...mapState<ComposedState>({
            categoryList: (state: ComposedState) => state.dreams.categoryList,
            userCurrency: (state: ComposedState) => state.dreams.createDreamUserCurrency,
            createStatus: (state: ComposedState) => state.dreams.createDreamApiStatus,
        }),
    },

    watch: {
        userCurrency(newCur) {
            if (newCur) this.dreamItem.currency_id = +newCur.id;
        },
    },

    methods: {
        ...mapActions({
            changeDreamItem: dreamsActions.changeDreamItem,
            createDreamItem:  dreamsActions.createDreamItem,
            loadUserCurrency: dreamsActions.loadUserCurrency,
        }),
        async createDream() {
            const pushFormData = new FormData();
            if (!this.dreamItem) {
                return;
            }
            for (const item in this.dreamItem) {
                pushFormData.append(item, this.dreamItem[item]);
            }

            await this.createDreamItem(pushFormData);

            // @ts-ignore
            if (this.createStatus === 'success') {
                this.$router.push('/dreams/list');
            }
        },
    },
});
